<template>
  <div class="wrapper">
    <Slider></Slider>
    <Sports></Sports>
    <div id="dp" class="stick date-filter">
      <div style="margin-bottom: 5px" class="highlight-box pt-0">
        <section class="bets-menu d-flex justify-content-between">
          <div v-if="highlights" class="w-20 active text-center">
            Highlights
          </div>
          <div v-else class="w-20 text-blue text-center" @click="setHighlight">
            <a>Highlights</a>
          </div>

          <div v-if="boosted" class="w-20 active text-center">Boosted</div>
          <div v-else class="w-20 text-blue text-center" @click="setBoosted">
            <a>Boosted</a>
          </div>

          <div v-if="upcoming" class="w-20 active text-center">Upcoming</div>
          <div v-else class="w-20 text-blue text-center" @click="setUpcoming">
            <a>Upcoming</a>
          </div>

          <div v-if="countries" class="w-20 active text-center">Countries</div>
          <div v-else class="w-20 text-blue text-center" @click="setCountries">
            <a>Countries</a>
          </div>

          <div v-if="jackpot" class="w-20 active text-center">Jackpot</div>
          <div v-else class="w-20 text-blue text-center" @click="setJackpot">
            <a>Jackpot</a>
          </div>

          <div class="w-20 px-3 text-blue d-none">Chap! Pick</div>
        </section>
      </div>

      <div class="buttons-sec nav-fill">
        <div class="dropdown pr-1">
          <Leagues></Leagues>
        </div>

        <div class="dropdown pr-1">
          <Markets></Markets>
        </div>
      </div>
    </div>

    <Games
      v-bind:highlights="highlights"
      v-bind:today="today"
      v-bind:upcoming="upcoming"
      v-bind:tomorrow="tomorrow"
      v-bind:sport="sport_id"
    >
    </Games>

    <BottomNavigation></BottomNavigation>
  </div>
</template>

<script>
const Slider = () => import('./Slider.vue');
// const Boosted = () => import('./Boosted.vue')
const Markets = () => import('./accordion/markets.vue');
const Sports = () => import('./accordion/sports.vue');
// const Countries = () => import('./accordion/countries.vue')
const Leagues = () => import('./accordion/leagues.vue');
const Games = () => import('./Games.vue');
const BottomNavigation = () => import('./BottomNavigation.vue');
import jQuery from 'jquery';

const $ = jQuery;

export default {
  name: 'Sport',
  metaInfo: {
    title: 'Choplife Sports Betting - Bet on Your Favorite Sports',
    meta: [
      {
        name: 'description',
        content:
          'Bet on football, basketball, rugby, and more with Choplife.  Enjoy live betting and win big. Sign up today!',
      },
      {
        name: 'keywords',
        content:
          'Sports betting, Online betting, Live betting, Betting site, Betting platform, Betting odds, Betting markets, Bet now, Bet online, Football betting, Soccer betting, Basketball betting, Rugby betting,' +
          'Tennis betting, Cricket betting, Betting bonuses, Freebet, Welcome bonus, Deposit bonus, Cashback, Loyalty program, Jackpot, Mega jackpot, In- play betting, Cash out, Live odds, Betting tips, Aviator,' +
          'Jetx, Cometcrash, Spin2wiin, Betting predictions, Virtual sports, Casino games, Live casino, Mobile betting, Betting app, Fast payouts, Secure betting, Responsible gambling, Customer support,' +
          'Kenya betting, Kenya sports betting, Bet in Kenya, Kenyan betting site, Aviator game, Freebet, OdiLeague, Bet slip, Multibet, Pawa6, WinBoost, Jackpots, Live score',
      },
    ],
  },
  components: {
    Leagues,
    // Countries,
    Markets,
    Sports,
    Slider,
    Games,
    BottomNavigation,
  },

  data: function () {
    return {
      highlights:
        this.$store.state.current_tab === 'highlights' ||
        !this.$store.state.current_tab,
      upcoming: this.$store.state.current_tab === 'upcoming',
      countries: this.$store.state.current_tab === 'countries',
      jackpot: this.$store.state.current_tab === 'jackpot',
      boosted: this.$store.state.current_tab === 'boosted',
      tomorrow: this.$store.state.current_tab === 'tomorrow',
      today: this.$store.state.current_tab === 'today',
      sub_page: '',
      mqttClient: '',
      homePagePopularGames: [
        // {
        //   providerID: 7,
        //   gameID: "aviator",
        //   gameName: "Aviator",
        //   image: "/img/home/Aviator.jpg",
        //   crashGameUrl: "aviator",
        // },
        {
          providerID: 7,
          gameID: 'aviator',
          gameName: 'Aviator',
          image: '/img/home/Aviator.jpg',
          crashGameUrl: 'aviator',
        },
        {
          providerID: 5,
          gameID: 'JetX',
          gameName: 'Jet X',
          image: '/img/home/JetX.jpg',
          crashGameUrl: 'jetx',
        },
        {
          providerID: 3,
          gameID: '1',
          gameName: 'Comet',
          image: '/img/home/CometCrash.jpg',
          crashGameUrl: 'comet',
        },
        {
          providerID: 4,
          gameID: 'rocketman',
          gameName: 'RocketMan',
          image: '/img/home/Rocketman.jpg',
          crashGameUrl: 'rocketman',
        },
        // {
        //   providerID: 3,
        //   gameID: "5",
        //   gameName: "Monkey Bizniz",
        //   image: "/img/home/Monkey.png",
        //   crashGameUrl: "monkeybizniz",
        // },
        {
          providerID: 5,
          gameID: 'FootballX',
          gameName: 'FootballX',
          image: '/img/home/FootballX.png',
          crashGameUrl: 'footballx',
        },
        // {
        //   providerID: 3,
        //   gameID: "9",
        //   gameName: "Street Dice",
        //   image: "/img/home/Dice.png",
        //   crashGameUrl: "streetdice",
        // },
      ],
    };
  },
  mounted() {
    //this.$store.dispatch("setSportID",1);
    this.$store.dispatch('setHour', -1);
    //this.$store.dispatch("setMarketID",1);
    this.$store.dispatch('setCompetitionID', 0);
    // this.$store.dispatch("setFixture", {});
    // reset game filter
    //this.$store.dispatch("resetAllGames");
    // var vm = this;
    this.reloadProfile();
    //  if(vm.getProfile().np == 1 && vm.getProfile().b1 == 0 ){
    //     document.getElementById("launch").click();
    //   }

    this.$store.dispatch('setCurrentPage', 'sport');
  },
  computed: {
    betslip_count: function () {
      return this.$store.state.betslip.total;
    },
    current_sub_page: function () {
      return this.$store.state.current_sub_page;
    },
    sport_id: function () {
      return this.$store.state.sport_id;
    },
  },
  methods: {
    getLaunchURL: function (launch_url) {
      var profile = this.getProfile();
      var tokn = profile.t1;

      // console.log('GOT launch_url '+launch_url);
      // console.log('GOT tokn '+tokn);

      launch_url = launch_url.replaceAll('profile_token', tokn);
      // console.log('GOT tokenized launch_url '+launch_url);

      return launch_url;
    },

    getKey: function (index) {
      return Math.random()
        .toString(10)
        .replace('0.', 'games-id-' + index + '-');
    },

    generateGameUrl(game) {
      return `/casinogames?IsDemo=0&providerID=${game.provider_id}&gameName=${game.game_name}&gameID=${game.game_id}&launchURL=${game.launch_url}&launchType=${game.launch_type}&launchParameters=${game.launch_parameters}`;
    },
    setUpcoming: function () {
      this.$store.dispatch('setCurrentTab', 'upcoming');
      this.highlights = false;
      this.today = false;
      this.tomorrow = false;
      this.upcoming = true;
    },
    setCountries: function () {
      this.$store.dispatch('setCurrentTab', 'countries');
      this.highlights = false;
      this.today = false;
      this.tomorrow = false;
      this.upcoming = true;
    },
    setJackpot: function () {
      this.$store.dispatch('setCurrentTab', 'jackpot');
      this.highlights = false;
      this.today = false;
      this.tomorrow = false;
      this.upcoming = true;
    },
    setToday: function () {
      this.$store.dispatch('setCurrentTab', 'today');
      this.highlights = false;
      this.today = true;
      this.tomorrow = false;
      this.upcoming = false;
    },
    setTomorrow: function () {
      this.$store.dispatch('setCurrentTab', 'tomorrow');
      this.highlights = false;
      this.today = false;
      this.tomorrow = true;
      this.upcoming = false;
    },
    setHighlight: function () {
      this.$store.dispatch('setCurrentTab', 'highlights');
      this.highlights = true;
      this.today = false;
      this.tomorrow = false;
      this.upcoming = false;
    },
    setBoosted: function () {
      this.$store.dispatch('setCurrentTab', 'boosted');
      this.highlights = true;
      this.today = false;
      this.tomorrow = false;
      this.upcoming = false;
    },
    setSubPage: function (page) {
      this.$store.dispatch('setCurrentSubPage', page);
      this.sub_page = page;
    },
    isHighlight: function () {
      return this.highlights;
    },
    isToday: function () {
      return this.today;
    },
    isUpcoming: function () {
      return this.upcoming;
    },

    isTomorrow: function () {
      return this.tomorrow;
    },

    geSubPageActiveClass: function (subpage) {
      return subpage === this.sub_page ? 'active' : '';
    },
    setSport: function (sportID) {
      this.$store.dispatch('setSportID', sportID);
    },
  },
};

window.addEventListener('scroll', () => {
  //var viewportOject = $('#infoY').offset().top - 50;
  var scrollPos = $(document).scrollTop();
  //console.log("vv"+viewportOject);
  //console.log("SS"+scrollPos);
  if (scrollPos >= 150) {
    // $("#dp").addClass("stick");
    //alert("I am here");
  } else {
    // $("#dp").removeClass("stick");
  }
});
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  /* gap: 0.5rem; */
}
.bets-menu {
  overflow: scroll;
  gap: 10px;
}
.bets-menu > div {
  padding: 8px 0;
}
.bets-menu > div a {
  color: #8c8787;
}

.bets-menu::-webkit-scrollbar {
  display: none;
}

.game-image {
  width: 90%;
  height: 90%;
  border-radius: 5px;
  object-fit: contain;
}

.game-image-container {
  width: 100%;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, 50px);
}

.modal-dialog {
  position: fixed !important;
  bottom: 0 !important;
  left: 0% !important;
  right: 0% !important;
  margin-bottom: 0 !important;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--gray-white);
}

.list-active {
  background-color: #f08b05;
}

.custom-divider {
  border-top: 2px solid var(--primary);
  border-bottom: 2px solid var(--primary);
}

.mobile-container {
  display: flex;
  flex-wrap: wrap;
}

.mobile-top-left-corner {
  background-color: white;
  border-top: 1.5px solid rgba(209, 201, 201, 0.979);
  border-left: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom: 1.5px solid rgba(209, 201, 201, 0.979);
  border-top-left-radius: 5px;
  padding: 5px;
  font-size: small;
  text-align: center;
}

.mobile-top-right-corner {
  background-color: white;
  border-top: 1.5px solid rgba(209, 201, 201, 0.979);
  border-left: 1.5px solid rgba(209, 201, 201, 0.979);
  border-right: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom: 1.5px solid rgba(209, 201, 201, 0.979);
  border-top-right-radius: 5px;
  padding: 5px;
  font-size: small;
  text-align: center;
}

.mobile-bottom-left-corner {
  background-color: white;
  border-left: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom-left-radius: 5px;
  padding: 5px;
  font-size: small;
  text-align: center;
}

.mobile-bottom-right-corner {
  background-color: white;
  border-left: 1.5px solid rgba(209, 201, 201, 0.979);
  border-right: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom: 1.5px solid rgba(209, 201, 201, 0.979);
  border-bottom-right-radius: 5px;
  padding: 5px;
  font-size: small;
  text-align: center;
}

span {
  font-weight: 700;
}

.date-filter {
  padding: 5px 20px ;
  border-top: 1px var(--light-gray) solid;
  border-bottom: 1px var(--light-gray) solid;
}
.bets-menu a {
  color: #8c8787;
  text-decoration: none;
}

.bets-menu a:not([href]),
a:not([href]):hover {
  text-decoration: none;
  color: #8c8787;
}
</style>
